import $ from 'jquery';
import 'jquery.easing';
import { Header } from './components/Header.js';
import { Contact } from './components/Contact.js';
// // import { SearchBox } from './components/SearchBox.js';
// // import { Product } from './components/Product.js';
// // import { Blog } from './components/Blog.js';
// import Slide from './components/Slide.js';
import { mediaQuery, scrollTo } from './utils/functions.js';

mediaQuery();
const headerInstance = Header();
Contact();

// Slide();

function requireAll(r){
  r.keys().forEach(r);
}


const resizeHandler = (e)=>{
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('load', resizeHandler);
window.addEventListener('resize', resizeHandler);

//check if the device is apple's product
const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
if(!isApple){
  document.body.classList.add('no-apple');
}


const pageScrollTo = (tar, duration, e) => {
  tar = tar.replace('#', '');

  const targetElement = document.querySelector(`[data-id="${tar}"]`);

  if (targetElement || tar === 'header') {

    if (e) {
      e.preventDefault();
    }

    headerInstance.closeNav();

    let diff = window.matchMedia('(max-width: 900px)').matches ? 64 : 64;

    let pos = tar === 'header' ? 0 : targetElement.getBoundingClientRect().top + window.scrollY - diff;

    // let speed = duration ? duration : 500;

    // Smooth scroll to the position
    scrollTo({
      to: pos
    });

    const navElements = document.querySelectorAll('.mobile-modal-site-nav, .modal-site-nav');
    navElements.forEach(nav => {
      if (nav.classList.contains('visible')) {
        nav.classList.remove('visible');
      }
    });
  }
};


document.querySelectorAll('.anchor, a').forEach(function(element) {
  element.addEventListener('click', function(e) {
    let href = e.currentTarget.getAttribute('href');
    //href = href.replace(window.theme.site_url, '').replace(window.theme.current_path, '');

    let tgt = e.currentTarget.getAttribute('href').split('#');
    if (tgt[1] && href === '#' + tgt[1]) {
      if (document.querySelector(`[data-id="${tgt[1]}"]`) || tgt[1] === 'header') {
        pageScrollTo(tgt[1], 500, e);
      }
    }
  });
});


const ratio = 0.6;
const prlxElems = document.querySelectorAll('.prlx');
const indicator = document.querySelector('.hero-section .indicator');
const heroContent = document.querySelector('.hero-section .section__content');

window.addEventListener('scroll', (e) => {

  const st = window.scrollY;

  if(st > 100){
    indicator.classList.add('indicator--hidden');
  } else {
    indicator.classList.remove('indicator--hidden');
  }


  prlxElems.forEach((elem, index) => {

//    console.log(elem.getBoundingClientRect().top);
    const thisY = elem.getBoundingClientRect().top + st;

    // console.log(thisY);

    if(thisY <= st + window.innerHeight * ratio){
      elem.classList.add('executed');
    }

  });

});

const timer = (func, time) => {
  setTimeout(() => {
    func();
  }, time);
}

timer(() => {
  heroContent.classList.add('visible');
}, 1000);

timer(() => {
  indicator.classList.add('visible');
}, 1500);






