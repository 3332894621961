

import * as Validator from 'validatorjs';
import axios from 'axios';



export function Contact() {


  let options = {};

  let rules =  {
    "name":'required|max:40',
    "email":'required|max:255|email',
    "phone":'required|max:15|telephone',
    "company_name":'required|max:100',
    "message": 'required|max:1000',
    "privacy": 'accepted'
  };
  // options.form = 'contact-form';
  // options.submit_button = 'contactFormSubmit';
  // options.thanks_page = 'form-success';
  const fields =  {
    'name': 'お名前',
    'email': 'メールアドレス',
    'phone': '電話番号',
    'company_name': '会社名',
    'message': 'メッセージ',
    'privacy': 'プライバシーポリシー'
  };
  
  const errorMessages = {
  
    "required.name": 'お名前を入力してください。',
    "max.name": 'お名前は40文字以内で入力してください。',
  
    "required.email": 'メールアドレスを入力してください。',
    "email.email": 'メールアドレスの形式が正しくありません。',
    "max.email": 'メールアドレスは255文字以内で入力してください。',
  
    "required.company_name": '会社名を入力してください。',
    "max.company_name": '会社名は100文字以内で入力してください。',

    "required.phone": '電話番号を入力してください。',
    "max.phone": '電話番号は15文字以内で入力してください。',
    "telephone.phone": '電話番号の形式が正しくありません。',
  
    "required.message": 'メッセージを入力してください。',
    "max.message": 'メッセージは100文字以内で入力してください。',

    "accepted.privacy": 'プライバシーポリシーに同意してください。',
  
  }
  
  const form = document.getElementById('contact-form');
  const submitButton = document.getElementById('submit-button');
  
  form.addEventListener('submit', (e) => {

    e.preventDefault();

    submitButton.disabled = true;
    submitButton.innerHTML = '送信しています...';


    form.querySelectorAll('.error').forEach((element) => {
      element.innerHTML = '';
    });
    
  
    let formData = new FormData(form);
    let param = {};
  
    formData.forEach((value, key) => {
      param[key] = value;
    });

    console.log(param);
  

    Validator.register('telephone', function(value, requirement, attribute) { // requirement parameter defaults to null
      return value.match(/^(0\d{1,4})-(\d{1,4})-(\d{4})$/);
    });

    let validation =  new Validator(param, rules, errorMessages);
  
    if(validation.fails()) {
      e.preventDefault();
      let validationErrors = validation.errors.all();
      console.log(validationErrors)
      Object.keys(validationErrors).forEach(function (key){
        if(validationErrors[key]){
          validationErrors[key].forEach(function(error, index) {
  
            Object.keys(fields).forEach(field_key => {
  
              if(key == field_key){
                let tgt_str = field_key.replace(/_/g, ' ');
                let str_to_replace = tgt_str;
                str_to_replace = fields[field_key];             
                error = error.replace(tgt_str, str_to_replace);
              }
  
            });
                                   
            let val = index == 0 ? error : '<br>' + error;
          
            //document.querySelector('[data-error="' + key + '"]').classList.add('visible');
            if(document.querySelector('[data-error="' + key + '"]')){
              document.querySelector('[data-error="' + key + '"]').innerHTML += val;
            }
          });
        }
      });
  
      submitButton.disabled = false;
      submitButton.innerHTML = '送信する';
    } else {


      submit(formData);

      submitButton.disabled = false;
      submitButton.innerHTML = '送信する';
      // Object.keys(fields).forEach(field_key => {
      //   document.querySelector('[name="' + field_key + '"]').value = '';
      // });
    }

  });


  async function submit(data) {

    const status = document.getElementById("form-status");
    const successContent = document.getElementById("contact-success");
    // const data = new FormData(event.target);
    
    axios({
      method: 'POST',
      url: 'https://formspree.io/f/mdknwpdn',
      data: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(response => {
      console.log(typeof response.status)
      if (response.status === 200) {
        
        form.style.display = "none";
        successContent.classList.add('active');

      } else {
        if (response.data && response.data.errors) {
          status.innerHTML = response.data.errors.map(error => error.message).join(", ");
        } else {
          status.innerHTML = "送信中にエラーが発生しました。時間をおいて再度お試しください。";
        }
      }
    }).catch(error => {
      status.innerHTML = "送信中にエラーが発生しました。時間をおいて再度お試しください。";
    });
  }
  


}
